import React from 'react';

const sizes = {
    textxs: 'text-xs font-medium',
    texts: 'text-sm font-medium not-italic',
    textmd: 'text-[17px] font-medium lg:text-sm',
    textxl: 'text-xl font-medium lg:text-[17px]',
    text6xl:
        'text-[56px] font-medium lg:text-[47px] md:text-5xl sm:text-[42px]',
    text7xl: 'text-[86px] font-medium lg:text-[86px] md:text-5xl',
    headingxs: 'text-sm font-bold',
    headings: 'text-base font-bold lg:text-[13px]',
    headingmd:
        'text-[28px] font-bold lg:text-[23px] md:text-[26px] sm:text-2xl',
    headinglg:
        'text-[46px] font-bold lg:text-[39px] md:text-[42px] sm:text-4xl',
};

export type HeadingProps = Partial<{
    className: string;
    as: any;
    size: keyof typeof sizes;
}> &
    React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLSpanElement>,
        HTMLSpanElement
    >;

const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
    children,
    className = '',
    size = 'headingmd',
    as,
    ...restProps
}) => {
    const Component = as || 'h6';

    return (
        <Component
            className={`font-dmsans text-white-a700 ${sizes[size]} ${className} `}
            {...restProps}
        >
            {children}
        </Component>
    );
};

export { Heading };
