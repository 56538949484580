import utils from '@/utils';
import React from 'react';

const shapes = {
    round: 'rounded-md',
} as const;

const variants = {
    outline: {
        pink_500_pink_A100: 'border border-solid pink_500_pink_A100_border',
        gray_600: 'border-gray-600 border border-solid !text-gray-500',
        red: 'border-red-600 border border-solid !text-gray-700 !font-bold',
    },
    none: 'border-none',
} as const;

const sizes = {
    xs: 'h-[40px] pl-4 pr-[34px] text-base',
    xp: 'h-[40px] px-4 text-base',
} as const;

type InputProps = Omit<
    React.ComponentPropsWithoutRef<'input'>,
    'prefix' | 'size'
> &
    Partial<{
        label: string;
        prefix: React.ReactNode;
        suffix: React.ReactNode;
        shape: keyof typeof shapes;
        variant: keyof typeof variants;
        size: keyof typeof sizes;
        color: keyof typeof variants.outline;
    }>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            className = '',
            name = '',
            placeholder = '',
            type = 'text',
            children,
            label = '',
            prefix,
            suffix,
            shape,
            value = '',
            variant = 'outline',
            size = 'xs',
            color = 'gray_600',
            onChange,
            onKeyUp,
            ...restProps
        },
        ref,
    ) => {
        const InputElement = () => {
            if (type == 'file') {
                const inputId = utils.randomStringUtils();
                return (
                    <>
                        <label
                            htmlFor={inputId}
                            className="w-full cursor-pointer opacity-50"
                        >
                            Select your image
                        </label>
                        <input
                            id={inputId}
                            ref={ref}
                            type={type}
                            name={name}
                            placeholder={placeholder}
                            onChange={onChange}
                            {...restProps}
                            className="hidden"
                        />
                    </>
                );
            }

            return (
                <input
                    ref={ref}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    {...restProps}
                />
            );
        };
        return (
            <label
                className={`${className} flex cursor-text items-center justify-center rounded-md border border-solid font-medium ${(shape && shapes[shape]) || ''} ${variants[variant]?.[color as keyof (typeof variants)[typeof variant]] || variants[variant] || ''} ${sizes[size] || ''}`}
            >
                {!!label && label}
                {!!prefix && prefix}
                {InputElement()}
                {!!suffix && suffix}
            </label>
        );
    },
);

export { Input };
