import { Dispatch, SetStateAction, useState } from 'react';

export default class Polling {
    setState: Dispatch<SetStateAction<NodeJS.Timeout | undefined>>;
    state: NodeJS.Timeout | undefined;
    delay: number;

    constructor(delay: number) {
        this.delay = delay;
        const [state, setState] = useState<NodeJS.Timeout>();

        this.setState = setState;
        this.state = state;
    }

    start(funct: VoidFunction) {
        this.stop();
        funct();
        this.setState(setInterval(() => funct(), this.delay));
    }
    stop() {
        clearInterval(this.state);
    }
}
